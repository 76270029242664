<template>
	<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			width="900"
			heigth="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
          class="ma-2"
					color="primary"
					dark
					v-bind="attrs"
					@click="dialog=true"
					v-on="on"
			>
				Рейтинг
			</v-btn>
		</template>
		<v-container class="grey lighten-5">
			<v-row>
				<v-col cols="6">
					<v-card>
						<v-card-text>
							<v-radio-group v-model="type">
                <v-radio :value="6" label="Отзывы со средней оценкой 4.9/5, рейтинг магазина - %."></v-radio>
                <v-radio :value="7" label="Более 0 отзывов со средней оценкой 4.9/5, магазин имеет статус &quot;Надежный бренд&quot; и рейтинг %."></v-radio>
                <v-radio :value="8" label="Более 0 отзывов со средней оценкой 4.9/5, рейтинг магазина - %."></v-radio>
                <v-radio :value="101" label="Отзывов пока мало"></v-radio>
                <v-radio :value="102" label="Отзывов пока нет"></v-radio>
								<v-radio :value="1" label="Отзывы со средней оценкой &quot;Отлично&quot;."></v-radio>
								<v-radio :value="2" label="Отзывы крайне положительные."></v-radio>
								<v-radio :value="3" label="Более 0 положительных отзывов."></v-radio>
								<v-radio :value="4" label="Более 0 отзывов на 5 звёзд."></v-radio>
								<v-radio :value="5" label="Отзывы со средней оценкой &quot;Отлично&quot;."></v-radio>
							</v-radio-group>
						</v-card-text>
						<v-card-actions>
							<v-btn color="primary" @click="dialog=false">Закрыть</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-card>
						<v-card-text>
							<v-text-field ref="textfield" v-model="textfield" :label="textfieldLabel" outlined></v-text-field>
							<v-text-field ref="textfield2" v-if="showSecondTextfield" v-model="textfield2" :label="textfield2Label" outlined></v-text-field>
							<v-text-field ref="textfield3" v-if="showThirdTextfield" v-model="textfield3" :label="textfield3Label" outlined></v-text-field>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    rating: String
  },
  data(){
    return{
      dialog: false,
      showSecondTextfield: false,
      showThirdTextfield: false,
      type: 0,
      // rat: '',
      textfield: '',
      textfieldLabel: '',
      textfield2: '',
      textfield2Label: '',
      textfield3: '',
      textfield3Label: ''
    }
  },
  watch:{
    rat(v){
      this.$emit('update:rating', v)
    },
    type(newVal, oldVal){
      console.log('type changed! new: ', newVal, ', old: ', oldVal)
      if (this.type !== oldVal){
        this.selectRadio()
      }
    },
  },
  computed:{
    rat(){
      const v = this.type
      if (v===101){
        return `Отзывов пока мало, средняя оценка ${this.textfield}/5.`
      }
      if (v===102){
        return 'Отзывов пока нет.'
      }
      if (v===1){
        return 'Отзывы со средней оценкой "Отлично".'
      }
      if (v===2){
        return 'Отзывы крайне положительные.'
      }
      if (v===3){
        return `Более ${this.textfield} положительных отзывов.`
      }
      if (v===4){
        return `Более ${this.textfield} отзывов на 5 звёзд.`
      }
      if (v===5){
        return 'Отзывы со средней оценкой "Отлично".'
      }
      if (v===6){
        return `Отзывы со средней оценкой ${this.textfield}/5, рейтинг магазина - ${this.textfield2}%.`
      }
      if (v===7){
        return`Более ${this.textfield} отзывов со средней оценкой ${this.textfield2}/5, магазин имеет статус "Надежный бренд" и рейтинг ${this.textfield3}%.`
      }
      if (v===8){
        return `Более ${this.textfield} отзывов со средней оценкой ${this.textfield2}/5, рейтинг магазина - ${this.textfield3}%.`
      }
    }
  },
  methods:{
    selectRadio(){
      const v = this.type
      console.log('select radio...', v)
      if (v===101){
        this.textfield = 4.9
        this.showSecondTextfield = false
        this.showThirdTextfield = false
        this.textfieldLabel = 'Оценка'
        // this.rat = `Отзывов пока мало, средняя оценка ${this.textfield}/5.`
        this.$refs.textfield.focus()
      }
      if (v===102){
        // this.rat = 'Отзывов пока нет.'
        this.dialog = false
      }
      if (v===1){
        // this.rat = 'Отзывы со средней оценкой "Отлично".'
        this.dialog = false
      }
      if (v===2){
        // this.rat = 'Отзывы крайне положительные.'
        this.dialog = false
      }
      if (v===3){
        this.textfieldLabel = 'Кол-во отзывов'
        // this.rat = `Более ${this.textfield} положительных отзывов.`
        this.$refs.textfield.focus()
      }
      if (v===4){
        this.textfieldLabel = 'Кол-во отзывов на 5 звезд'
        // this.rat = `Более ${this.textfield} отзывов на 5 звёзд.`
        this.$refs.textfield.focus()
      }
      if (v===5){
        // this.rat = 'Отзывы со средней оценкой "Отлично".'
        this.dialog = false
      }
      if (v===6){
        this.showSecondTextfield = true
        this.textfield = 4.9
        this.textfieldLabel = 'Средняя оценка (/5)'
        this.textfield2Label = 'Рейтинг магазина (%)'
        // this.rat = `Отзывы со средней оценкой ${this.textfield}/5, рейтинг магазина - ${this.textfield2}%.`
        this.$refs.textfield.focus()
      }
      if (v===7){
        this.showSecondTextfield = true
        this.showThirdTextfield = true
        this.textfieldLabel = 'Кол-во отзывов'
        this.textfield2Label = 'Средняя оценка (/5)'
        this.textfield2 = 4.9
        this.textfield3Label = 'Рейтинг (%)'
        // this.rat = `Более ${this.textfield} отзывов со средней оценкой ${this.textfield2}/5, магазин имеет статус "Надежный бренд" и рейтинг ${this.textfield3}%.`
        this.$refs.textfield.focus()
      }
      if (v===8){
        this.showSecondTextfield = true
        this.showThirdTextfield = true
        this.textfieldLabel = 'Кол-во отзывов'
        this.textfield2Label = 'Средняя оценка (/5)'
        this.textfield = ''
        this.textfield2 = 4.9
        this.textfield3 = 99
        this.textfield3Label = 'Рейтинг (%)'
        // this.rat = `Более ${this.textfield} отзывов со средней оценкой ${this.textfield2}/5, рейтинг магазина - ${this.textfield3}%.`
        this.$refs.textfield.focus()
      }
    }
  }
}
</script>

<style scoped>

</style>
